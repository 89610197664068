// init
import axios from "axios";
const basemedia = 'https://ap-southeast-1.aws.data.mongodb-api.com/app/tokenize-media-nzzzz/endpoint'
const basedocs = 'https://ap-southeast-1.aws.data.mongodb-api.com/app/application-0-ffzxp/endpoint'

// -----------------


export const AssetDocumentsListMedia = async (item) => {
  
  const basx = basemedia + '/documents/list/asset/media';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = {data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC}

  var result;
  try {
    result = await axios.post(basx, datx, { headers: head })
    // console.log(result)
    return { code: result.status, ...result.data }
  } catch (error) {
    // console.log(error)
    return { code: error.response.status, ...error.response.data }
  }
}


export const AssetDocumentsListVideo = async (item) => {
  
  const basx = basemedia + '/documents/list/asset/video';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = {data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC}

  var result;
  try {
    result = await axios.post(basx, datx, {headers: head});
    // console.log (result)
    return ([200, 201].includes(result.status)) ? result.data : {data: false}
  } catch (error) {
    // console.log (error);
    return {data: false}
  }
  
}


export const AssetDocumentsListDocs = async (item) => {
  
  const basx = basedocs + '/document/list/asset/docs';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = {data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC}

  var result;
  try {
    result = await axios.post(basx, datx, {headers: head});
    // console.log (result)
    return ([200, 201].includes(result.status)) ? result.data : {data: false}
  } catch (error) {
    // console.log (error);
    return {data: false}
  }
  
}
