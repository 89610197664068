// user
import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";

import WebbDividerSmall from "../webx/webb-divider-sm";
import WebbDividerMedium from "../webx/webb-divider-md";
import WebbLoaderMedium from "../webx/webb-loader-md";
import WebbLoaderSmall from "../webx/webb-loader-sm";
import WebbModuleInfo from "../webx/webb-module-info";
import FormNeeded from "../webx/form-needed";

import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-user";

import { UserDocumentList } from "../../services/srvc-documents-realm";

import listActions from '../../data.static/data-user-onboard.json'

const listDocs = [
  {name: 'Pan Card', sort: 'taxc', item: '25782623593d44e4bff02487f03befe57'},
  {name: 'Aadhaar Card', sort: 'adhr', item: '9001cd7381e745d19e55a1365a1f4b9e2'},
  {name: 'Utility Bill', sort: 'util', item: '6661bb7e4c604488bb5d9442ff39ad939'}
]


export default function UserDocumentsListModule () {

  const asset = GetLocalUser()
  const team = GetLocalBusiness()
  // console.log(team)

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState()

  useEffect( () => {
    if (asset){

      const fetchData = async() => {
        setLoading(true);

        const result = await UserDocumentList({data: {user: asset.item}})
        console.log (result)

        if (result.data) {
          const listx = Array.from(result.data.list, x => { return {
            ...x, 
            link: listActions.data.find(z => z.item == x.meta.format).link
          }})
          setData(listx)
        }

        setLoading(false);
      }
      fetchData()
    } else {}
  },[]);

  const handleClick = (link) => {
    navigate(link)
  }


  if (loading) return <><div className="mx-3 text-color-tone">Please Wait...</div></>
  if (!loading && data && data.length === 0) return <>
    <div className="mx-3">No Documents Linked</div>
  </>

  return (
  <>
    {/* data */}
    <div className="back-color-wite rounded-xd border">
    {data && data.map((item, i) => (
      <div key={i}>
      <div className={`d-flex px-3 mt-3 mb-3`} >
          
        <div className="me-auto">         
          <p className="m-0 text-sm">
            <span className="text-color-next">{item.meta.name}</span>
          </p>
          <p className="text-small m-0 text-sm">
            <span className="">Number: {item.meta.number ||'******'}</span>
          </p>
          <p className="text-small m-0 text-sm d-none">
            <span className="">Verified: {item.status ? 'Yes' : 'No'}</span>
          </p>          
        </div>
    
        <div className="text-end">         
          {item.active ? 'Linked' : <Link to={item.link}>Click Here</Link>}
        </div>

      </div>
      <div className={i < data.length-1 ? 'border-bottom': ''}></div>
      </div>
    ))}
    </div> 

  </>

  )
}